import * as React from "react";
import styled from "@emotion/styled";
import css from "@emotion/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

import backgroundImg from "./IMG_0723.jpg";
import mugshotSrc from "./mugshot.jpg";
import { MainFont, HeaderFont } from "../Fonts";

export const Page = () => {
  return (
    <Layout>
      <CenterPanel>
        <AvatarArea>
          <Avatar src={mugshotSrc} />
          <Title>Jack Davis</Title>
          <p>Software Architect, Application Developer, DevOps</p>
        </AvatarArea>
        <SubPanel>
          <DescriptionText>
            <h2>Applications</h2>
            <p>Typescript React Apps</p>
            <p>GraphQL APIs</p>
            <h2>DevOps</h2>
            <p>Serverless</p>
            <p>Docker and Kubernetes</p>
            <p>CI / CD</p>
            <p>GitOps</p>
            <h2>Solution Architecture</h2>
            <p>Startups</p>
            <p>Corporate</p>
            <p>Blockchain</p>
          </DescriptionText>
          <Divider />
          <SocialList>
            <SocialListItem
              link="https://www.linkedin.com/in/jack-davis-19b33370/"
              icon={faLinkedin}
            >
              LinkedIn
            </SocialListItem>
            <SocialListItem
              link="http://github.com/mrjackdavis"
              icon={faGithub}
            >
              GitHub
            </SocialListItem>
          </SocialList>
        </SubPanel>
      </CenterPanel>
    </Layout>
  );
};

const Breakpoints = {
  first: `(min-width: 400px)`,
  second: `(min-width: 700px)`
};

const SocialListItem: React.ComponentType<{
  icon: IconDefinition;
  link: string;
}> = props => (
  <SocialListItemContainer href={props.link}>
    <FontAwesomeIcon icon={props.icon} /> <span>{props.children}</span>
  </SocialListItemContainer>
);

const Paper = styled.div`
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  background: #f8f8f8;
`;

const Layout = styled.div`
  @media ${Breakpoints.first} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${backgroundImg});
    background-size: cover;
    background-position: center;
    background-image: url(${backgroundImg});
  }

  min-height: 100vh;
  width: 100%;
`;

const CenterPanel = styled(Paper)`
  color: #f3f3f3;
  background: linear-gradient(to bottom right, #444, #333);

  display: flex;

  flex-direction: column;
  align-items: stretch;

  padding-bottom: 1rem;

  @media ${Breakpoints.first} {
    padding-bottom: 0;
    margin-bottom: 5rem;
    margin-top: 8rem;
  }

  @media ${Breakpoints.second} {
    flex-direction: row;
    align-items: center;
    margin-left: -5rem;
  }
`;

const SubPanel = styled(Paper)`
  color: rgba(0, 0, 0, 0.8);
  background: #f3f3f3;

  flex: 1 0 auto;

  border-top: orange 12px solid;
  box-sizing: border-box;
  margin-left: 1.2rem;
  margin-right: 1.2rem;

  @media ${Breakpoints.first} {
    padding-bottom: 0;
    margin-bottom: -4rem;
  }

  @media ${Breakpoints.second} {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: -5rem;
  }
`;

const SocialListItemContainer = styled.a`
  ${MainFont}

  display: flex;

  flex: 1 0 auto;
  padding: 0.8rem;
  box-sizing: border-box;

  flex-direction: row;
  justify-content: center;

  font-size: 1.2rem;
  color: inherit;
  text-decoration: none;

  span {
    padding-left: 1rem;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const SocialList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
`;

const avatarSize = 140;

const Avatar = styled.img`
  display: block;
  height: ${avatarSize}px;
  width: ${avatarSize}px;
  border-radius: ${avatarSize / 2}px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  border: 4px solid #f3f3f3;

  margin-top: 50px;

  @media ${Breakpoints.first} {
    margin-top: -${avatarSize / 2}px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;

  padding-top: 2rem;
  padding-bottom: 1rem;

  margin-bottom: 1rem;

  ${HeaderFont};
`;

const TextArea = styled.div`
  padding: 1rem;
  ${MainFont}
`;

const Divider = styled.hr`
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;
  border-top: none;
  padding: 0;
  margin: 0;
`;

const AvatarArea = styled(TextArea)`
  flex: 0 0 200px;
  text-align: center;

  @media ${Breakpoints.second} {
    flex: 0 0 250px;
  }

  p {
    line-height: 2rem;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const DescriptionText = styled(TextArea)`
  h2 {
    font-size: 1.3rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  p {
    padding: 0.3rem;
    color: rgba(0, 0, 0, 0.5);
  }
`;
