import * as React from "react";
import { Global, css } from "@emotion/core";

export const ImportFonts = () => (
  <Global
    styles={css`
      @import url("https://fonts.googleapis.com/css?family=Alegreya|Ubuntu");
    `}
  />
);

export const HeaderFont = `
  font-family: 'Alegreya', serif;
`;

export const MainFont = `
  font-family: 'Ubuntu', sans-serif;
`;
