import * as React from "react";
import emotionReset from "emotion-reset";
import { Global, css } from "@emotion/core";

export const GlobalStyles = () => <Global styles={style} />;

const style = css`
  @import url("https://fonts.googleapis.com/css?family=Alegreya|Ubuntu");

  html {
  }
  body {
    margin: 0;
    padding: 0;

    background: #fdfdfd;
  }

  ${emotionReset}
`;
