import React, { Component } from "react";
import { Page } from "./components/Page";
import { GlobalStyles } from "./components/GlobalStyles";
import { ImportFonts } from "./components/Fonts";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <GlobalStyles />
        <ImportFonts />
        <Page />
      </React.Fragment>
    );
  }
}

export default App;
